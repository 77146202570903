import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/activity/couponevent',
    children: [
      {
        path: 'activity/couponevent',
        name: 'couponevent',
        meta: {
          title: 'HolilandPet优惠券'
        },
        component: () => import('../views/activity/couponevent/index.vue')
      },
      {
        path: 'activity/couponevent/qrcode',
        name: 'couponevent-qrcode',
        meta: {
          title: 'HolilandPet优惠券'
        },
        component: () => import('../views/activity/couponevent/qrcode.vue')
      }
    ]
  },
  {
    path: '/ad',
    name: 'ad',
    meta: {
      title: '详情'
    },
    component: () => import('../views/ad/index.vue')
  },
  {
    path: '/wechat_login',
    name: 'wechat_login',
    meta: {
      title: '微信授权'
    },
    component: () => import('../views/wechat_login/index.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
